import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Urls from '../../urls';
import StorageInfo from '../../account/StorageInfo';
import storage from '../storage';
import { withNamespaces } from 'react-i18next';
import { AppContext, isLoggedIn, isSuperuser } from '../../context/global';
import FreshDesk from '../../integrations/FreshDesk';

class LeftSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.sidebarElements = React.createRef();
    this.storageKey = 'ff_collapse_left_sidebar';
    this.state = {
      isCoordinator: false,
    };
  }

  componentDidMount() {
    const $el = $('.be-collapsible-sidebar');
    if (this.isClosed()) {
      $el.addClass('be-collapsible-sidebar-collapsed');
      this.closeAll();
    } else {
      $el.removeClass('be-collapsible-sidebar-collapsed');
    }
    import('../beagle' /* webpackChunkName: 'beagleapp' */).then((module) => {
      module.BeagleApp().init();
    });
  }

  isClosed() {
    return this.props.collapsed || storage.getItem(this.storageKey) === 'closed';
  }

  toggleSidebar(e) {
    const $el = $('.be-collapsible-sidebar');
    const isClosed = $el.hasClass('be-collapsible-sidebar-collapsed');
    if (isClosed) {
      $el.removeClass('be-collapsible-sidebar-collapsed');
      storage.setItem(this.storageKey, 'opened');
    } else {
      $el.addClass('be-collapsible-sidebar-collapsed');
      storage.setItem(this.storageKey, 'closed');
    }
    this.closeAll();
  }

  closeAll() {
    if (this.isClosed()) {
      $(this.sidebarElements.current).find('.open').removeClass('open');
      $(this.sidebarElements.current).find('.visible').removeClass('visible');
    }
  }

  render() {
    const { i18n } = this.props;

    let group = null;

    if (
      (location.pathname.indexOf('/documents/') > -1 ||
        location.pathname.indexOf('/templates/') > -1 ||
        location.pathname.indexOf('/modelos/') > -1) &&
      !location.search.includes('presentation')
    ) {
      group = 'documents';
    } else if (location.pathname.indexOf('/presentations/') > -1 || location.search.includes('presentation')) {
      group = 'presentations';
    } else if (location.pathname.indexOf('/references/') > -1) {
      group = 'references';
    } else if (location.pathname.indexOf('/reports/') > -1) {
      group = 'reports';
    } else if (location.pathname.indexOf('/help/') > -1) {
      group = 'help';
    } else if (location.pathname.indexOf('/advisory/') > -1) {
      group = 'advisory';
    }

    const currentLang = i18n.language === 'pt-br' ? 'pt' : i18n.language;

    return (
      <div className="be-left-sidebar">
        <div className="left-sidebar-wrapper">
          <a href="#" className="left-sidebar-toggle">
            <i className="mdi mdi-menu" />
            &nbsp; {this.props.title}
          </a>
          <div className="left-sidebar-spacer">
            <div className="left-sidebar-scroll">
              <div className="left-sidebar-content">
                <ul className="sidebar-elements" ref={this.sidebarElements}>
                  <li className="hidden-xs">
                    <a role="button" href="#" onClick={this.toggleSidebar}>
                      <i className="icon mdi mdi-menu" />
                      <span style={{ display: 'none' }}>Hide</span>
                    </a>
                  </li>

                  <li className={group === 'documents' ? 'parent active open' : 'parent'}>
                    <Link to={Urls.myDocuments}>
                      <i className="icon far fa-file"> </i>
                      <span>{i18n.t('Documentos')}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li
                        className={location.pathname === Urls.myDocuments || location.pathname === '/' ? 'active' : ''}
                      >
                        <Link to={Urls.myDocuments} onClick={this.closeAll}>
                          {i18n.t('Meus documentos')}
                        </Link>
                      </li>
                      <li className={location.pathname === Urls.sharedDocuments ? 'active' : ''}>
                        <Link to={Urls.sharedDocuments} onClick={this.closeAll}>
                          {i18n.t('Compartilhados comigo')}
                        </Link>
                      </li>
                      <li className={location.pathname === Urls.trashDocuments ? 'active' : ''}>
                        <Link to={Urls.trashDocuments} onClick={this.closeAll}>
                          {i18n.t('Lixeira')}
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname.includes(Urls.templates) && location.search.includes('document')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to={Urls.documentTemplates} onClick={this.closeAll}>
                          <span>{i18n.t('Modelos')}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className={group === 'references' ? 'parent active open' : 'parent'}>
                    <Link to={Urls.references}>
                      <i className="icon mdi mdi-bookmark-outline"> </i>
                      <span>{i18n.t('Referências')}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li className={location.pathname === Urls.references ? 'active' : ''}>
                        <Link to={Urls.references} onClick={this.closeAll}>
                          {i18n.t('Minhas referências')}
                        </Link>
                      </li>
                      <li className={location.pathname.includes(Urls.fastref) ? 'active' : ''}>
                        <Link to={Urls.fastref} onClick={this.closeAll}>
                          {i18n.t('Banco de referências')}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className={group === 'presentations' ? 'parent active open' : 'parent'}>
                    <Link to={Urls.myPresentations}>
                      <i className="icon mdi mdi-slideshow"> </i>
                      <span>{i18n.t('Apresentações')}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li
                        className={
                          location.pathname === Urls.myPresentations || location.pathname === '/' ? 'active' : ''
                        }
                      >
                        <Link to={Urls.myPresentations} onClick={this.closeAll}>
                          {i18n.t('Minhas apresentações')}
                        </Link>
                      </li>
                      <li className={location.pathname === Urls.sharedPresentations ? 'active' : ''}>
                        <Link to={Urls.sharedPresentations} onClick={this.closeAll}>
                          {i18n.t('Compartilhadas comigo')}
                        </Link>
                      </li>
                      <li className={location.pathname === Urls.trashPresentations ? 'active' : ''}>
                        <Link to={Urls.trashPresentations} onClick={this.closeAll}>
                          {i18n.t('Lixeira')}
                        </Link>
                      </li>
                      <li className={location.search.includes('presentation') ? 'active' : ''}>
                        <Link to={Urls.presentationTemplates} onClick={this.closeAll}>
                          <span>{i18n.t('Modelos')}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/*<li className={this.props.group === 'groups' ? 'parent active open' : 'parent'}>*/}
                  {/*    <a role="button">*/}
                  {/*        <i className="icon mdi mdi-accounts"> </i>*/}
                  {/*        <span>{i18n.t('Grupos')}</span>*/}
                  {/*    </a>*/}
                  {/*    <ul className="sub-menu">*/}
                  {/*        <li>*/}
                  {/*            <Link to={Urls.documents}>*/}
                  {/*                <span className="mdi mdi-plus"/> {i18n.t('Adicionar')}*/}
                  {/*            </Link>*/}
                  {/*        </li>*/}
                  {/*        <li>*/}
                  {/*            <Link to={Urls.documents}>*/}
                  {/*                Disciplina xxx*/}
                  {/*            </Link>*/}
                  {/*        </li>*/}
                  {/*        <li>*/}
                  {/*            <Link to={Urls.documents}>*/}
                  {/*                Disciplina xxx*/}
                  {/*            </Link>*/}
                  {/*        </li>*/}
                  {/*        <li>*/}
                  {/*            <Link to={Urls.documents}>*/}
                  {/*                Disciplina xxx*/}
                  {/*            </Link>*/}
                  {/*        </li>*/}
                  {/*    </ul>*/}
                  {/*</li>*/}

                  {isSuperuser() && (
                    <li className={group === 'reports' ? 'parent active open' : 'parent'}>
                      <a href="#">
                        <i className="icon mdi mdi-chart"> </i>
                        <span>Reports</span>
                      </a>
                      <ul className="sub-menu">
                        <li id="users-menu" className={location.pathname === Urls.reportUsers ? 'active' : ''}>
                          <Link to={Urls.reportUsers}>Users</Link>
                        </li>
                        <li
                          id="institutions-menu"
                          className={location.pathname === Urls.reportInstitutions ? 'active' : ''}
                        >
                          <Link to={Urls.reportInstitutions}>Instituições</Link>
                        </li>
                        <li id="checkouts-menu" className={location.pathname === Urls.reportCheckouts ? 'active' : ''}>
                          <Link to={Urls.reportCheckouts}>Checkouts</Link>
                        </li>
                        <li id="checkouts-menu" className={location.pathname === Urls.reportAreas ? 'active' : ''}>
                          <Link to={Urls.reportAreas}>Knowledge areas</Link>
                        </li>
                        <li
                          id="report-paid-document-statistics"
                          className={location.pathname === Urls.reportPaidDocuments ? 'active' : ''}
                        >
                          <Link to={Urls.reportPaidDocuments}>Statistics for Paid Documents</Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  <li className={group === 'help' ? 'parent active open' : 'parent'}>
                    <Link to={Urls.videos}>
                      <i className="icon mdi mdi-help"> </i>
                      <span>{i18n.t('Ajuda')}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li className={location.pathname === Urls.videos ? 'active' : ''}>
                        <Link to={Urls.videos} onClick={this.closeAll}>
                          {i18n.t('Vídeos')}
                        </Link>
                      </li>
                      <li className={location.pathname === Urls.faq ? 'active' : ''}>
                        <Link to={Urls.faq} onClick={this.closeAll}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <FreshDesk showIcon={false} />
                      </li>
                    </ul>
                  </li>
                  <li className={group === 'advisory' ? 'active' : ''}>
                    <Link to={Urls.advisoryCourses}>
                      <i className="icon mdi mdi-videocam"> </i>
                      <span>Cursos e Assessoria</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {isLoggedIn() && <StorageInfo />}
        </div>
      </div>
    );
  }
}

LeftSidebar.propTypes = {
  title: PropTypes.string,
  active: PropTypes.oneOf(['my-references', 'fastref']),
  group: PropTypes.oneOf(['documents', 'references', 'templates', 'reports', 'account', 'help']),
  collapsed: PropTypes.bool,
};

LeftSidebar.contextType = AppContext;

export default withNamespaces()(LeftSidebar);
