import React from 'react';
import Config from '../config';
import Ajax from '../common/ajax';
import Waiting from '../common/containers/Waiting';
import storage from '../common/storage';
import { withNamespaces } from 'react-i18next';
import Button from '../common/components/Button';
import ProgressBar from '../common/components/ProgressBar';

class StorageInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      storageInfo: {},
    };
    this.storageKey = 'ff_user_storage_info';
    this.forceRefresh = this.forceRefresh.bind(this);
  }

  componentDidMount() {
    if (!storage.getItem(this.storageKey)) {
      this.fetchUsedStorage();
    } else {
      this.setState({
        ...this.state,
        storageInfo: JSON.parse(storage.getItem(this.storageKey)),
      });
    }
  }

  fetchUsedStorage(force = false) {
    this.setState({
      ...this.state,
      processing: true,
    });
    const url = `${Config.apiHost}quotas/storage_info/${force ? '?update=1' : ''}`;
    Ajax.get(url)
      .done((storageInfo) => {
        if (force) {
          setTimeout(() => {
            // Since updating storage is async, try to perform another fetch delayed to get the updated storage.
            this.fetchUsedStorage();
          }, 5000);
        } else {
          this.setState((state) => ({
            ...state.state,
            processing: false,
            storageInfo,
          }));
          storage.setItem(this.storageKey, JSON.stringify(storageInfo), 60 * 3);
        }
      })
      .fail(() => {
        this.setState({
          ...this.state,
          processing: false,
          storageInfo: null,
        });
      });
  }

  forceRefresh() {
    storage.removeItem(this.storageKey);
    this.fetchUsedStorage(true);
  }

  render() {
    if (!this.state.storageInfo) {
      return null;
    }

    const { i18n } = this.props;

    const storageUsed = this.state.storageInfo.used_percentage ? Math.round(this.state.storageInfo.used_percentage) : 0;
    const barStyle = storageUsed >= 90 ? 'danger' : storageUsed >= 80 ? 'warning' : 'primary';

    return (
      <Waiting isProcessing={this.state.processing}>
        <div className="progress-widget">
          <div className="progress-data" title="Armazenamento">
            <span className="name">
              &nbsp;
              {i18n.t('Armazen.')}
            </span>
            <span className="progress-value">
              {storageUsed}% {i18n.t('de')} {this.state.storageInfo.limit}
              <Button size="xs" onClick={this.forceRefresh} type="link">
                <i className="mdi mdi-refresh" />
              </Button>
            </span>
          </div>
          <ProgressBar percentage={storageUsed} barStyle={barStyle} />
        </div>
      </Waiting>
    );
  }
}

export default withNamespaces()(StorageInfo);
