import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends Component {
  render() {
    const { percentage, label, barStyle, animated, stripped } = this.props;

    const classes = ['progress-bar', `progress-bar-${barStyle}`];

    if (stripped) {
      classes.push('progress-bar-striped');
    }

    if (animated) {
      classes.push('active');
    }

    return (
      <div className="progress" style={{ height: '22px' }}>
        <div style={{ width: `${percentage}%` }} className={classes.join(' ')}>
          {label ? `${label} (${percentage}%)` : `${percentage}%`}
        </div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  label: PropTypes.string,
  barStyle: PropTypes.oneOf(['primary', 'warning', 'danger']),
  stripped: PropTypes.bool,
  animated: PropTypes.bool,
};

ProgressBar.defaultProps = {
  percentage: 0,
  label: '',
  barStyle: 'primary',
};

export default ProgressBar;
